

























import Vue from "vue";
export default Vue.extend({
  name: "InstructionsDialogBox",
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    instructions: {
      type: String,
      required: true
    }
  }
});























































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ViewAssessmentsOptions } from "@/interfaces/candidate/canidate_assessments";
import InterviewLoading from "@/components/candidate/interviews/InterviewLoading.vue";
import {
  AssessmentChatRoles,
  SubmitAssessmentAnswersApiPayload
} from "@/store/modules/candidates/interfaces";
import {
  APP_ANNOTATION_ASSESSMENT_CANCELLED,
  APP_ANNOTATION_ASSESSMENT_COMPLETED,
  APP_ANNOTATION_CURRENT_QUESTION_INDEX,
  APP_ASSESSMENT_SS,
  ASSESSMENT_QUESTIONNAIRES,
  ASSESSMENT_QUESTIONNAIRES_ANNOTATION,
  ASSESSMENT_QUESTIONNAIRES_ANNOTATION_UPDATE,
  SUBMIT_APP_ASSESSMENT_ANSWERS,
  SUBMIT_APP_ASSESSMENT_MEDIA,
  CAMERA_RECORDING_SUPPORTED,
  SCREEN_RECORDING_SUPPORTED,
  ASSESSMENT_AVERAGE_TIME_STATS
} from "@/store/modules/candidates/constants";
import {
  GET_COMPANY_DETAILS,
  GET_USER_DETAILS
} from "@/store/modules/auth/constants";
import {
  APP_ASSESSMENTS,
  SITE_DIRECTION,
  UPLOAD_FILE_CHUNK
} from "@/store/modules/common/constants";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import AppAnnotationPart1 from "./AppAnnotationPart1.vue";
import AppAnnotationPart2 from "./AppAnnotationPart2.vue";
import AppAnnotationPart3 from "./AppAnnotationPart3.vue";
import AppAnnotationPart4 from "./AppAnnotationPart4.vue";
import AppAnnotationPart5 from "./AppAnnotationPart5.vue";
import AppAnnotationPart6 from "./AppAnnotationPart6.vue";
import AppAnnotationPart7 from "./AppAnnotationPart7.vue";
import AppAnnotationPart8 from "./AppAnnotationPart8.vue";
import AccessDeniedComponent from "@/components/candidate/app_assessments/AccessDeniedComponent.vue";
import AppResourseNotFound from "@/components/candidate/app_assessments/AppResourseNotFound.vue";
import AppTimer from "@/components/AppTimer.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import ProgressUploader from "@/components/shared/ProgressUploader.vue";
import {
  AnnotationPartInterface,
  OptionsPart1,
  AnnotationAnswers
} from "@/store/modules/candidates/interfaces";
import socketService from "@/services/socket.service";
import { AssessmentRecordType } from "@/interfaces/app.interface";
import { get_random_number_between } from "@/utils/global";
export default Vue.extend({
  name: "AppAnnotationAssessmentRoot",
  components: {
    InterviewLoading,
    AppAnnotationPart1,
    AppAnnotationPart2,
    AppAnnotationPart3,
    AppAnnotationPart4,
    AppAnnotationPart5,
    AppAnnotationPart6,
    AppAnnotationPart7,
    AppAnnotationPart8,
    AccessDeniedComponent,
    AppResourseNotFound,
    AppTimer,
    ProgressUploader
  },
  data() {
    return {
      error: "" as string, // Global error message
      loading: false as boolean, // Global loading
      media_stopped: false as boolean,
      screen_recroding_start_time: new Date(), // For screen recording start time
      screen_recroding_end_time: new Date(), // For screen recording end time
      camera_recording_start_time: new Date(), // For camera recording start time
      camera_recording_end_time: new Date(), // For camera recording end time
      // For screen recording
      media_stream: null as MediaStream | null, // For screen recording stream
      media_enabled: false as boolean, // To check if screen recording is enabled or not
      screen_recorder: null as MediaRecorder | null, // For screen recording
      screen_recoding_chunks: [] as Blob[], // For screen recording chunks
      // For camera/audio recording
      camera_media_stream: null as MediaStream | null, // For camera stream
      camera_media_recorder: null as MediaRecorder | null, // For camera recording
      camera_media_enabled: false as boolean, // To check if camera is enabled or not
      camera_recorded_blobs: [] as Blob[], // For camera recording chunks
      // eslint-disable-next-line no-undef
      speech_recognition: null as typeof window.webkitSpeechRecognition | null,
      current_question: null as AppAssessments.AssessmentQuestionnaires | null, // To store current question
      current_question_answer: null as string | null | JSON, // To store current question answer
      submit_ans_loading: false as boolean, // To check if user answer is submitting or not
      disabled_next_question: true as boolean, // To disable next button
      // For Uploading camera/media recording files
      media_uploading: false, // To check if media uploading is in progress or not
      media_uploading_title: "", // Media uploading title
      media_uploading_progress: 0, // Media uploading progress
      screen_recording_upload_chuk_size: 6 * 1024 * 1024,
      camera_recording_upload_chuk_size: 6 * 1024 * 1024,
      ann_answer_time: 0,
      ann_part_time: 0,
      ann_assessment_time: 0,
      timerStarted: false,
      timers: {} as Record<string, ReturnType<typeof setInterval>> // Store multiple timer IDs
    };
  },
  computed: {
    ViewAssessmentsOptions() {
      return ViewAssessmentsOptions;
    },
    AssessmentChatRoles() {
      return AssessmentChatRoles;
    },
    AppAssessments() {
      return AppAssessments;
    },
    ...mapGetters("candidate", {
      app_annotation_assessments: ASSESSMENT_QUESTIONNAIRES_ANNOTATION,
      current_question_index: APP_ANNOTATION_CURRENT_QUESTION_INDEX,
      assessment_cancelled: APP_ANNOTATION_ASSESSMENT_CANCELLED,
      assessment_completed: APP_ANNOTATION_ASSESSMENT_COMPLETED,
      assessment_ss: APP_ASSESSMENT_SS,
      get_camera_recording_supported: CAMERA_RECORDING_SUPPORTED,
      get_screen_recording_supported: SCREEN_RECORDING_SUPPORTED
    }),
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS,
      get_company_details: GET_COMPANY_DETAILS
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS,
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  created() {
    // Random number between 8-15
    const screen_random_number = get_random_number_between(8, 15);
    const camera_random_number = get_random_number_between(8, 15);
    this.screen_recording_upload_chuk_size = screen_random_number * 1024 * 1024;
    this.camera_recording_upload_chuk_size = camera_random_number * 1024 * 1024;
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeUnload);
    this.init_process(); // To start the process
  },
  methods: {
    ...mapMutations("candidate", {
      set_app_annotation_assessments: ASSESSMENT_QUESTIONNAIRES_ANNOTATION,
      increment_question_index: APP_ANNOTATION_CURRENT_QUESTION_INDEX,
      update_assessment_cancelled: APP_ANNOTATION_ASSESSMENT_CANCELLED,
      update_assessment_completed: APP_ANNOTATION_ASSESSMENT_COMPLETED,
      update_app_annotation_assessment:
        ASSESSMENT_QUESTIONNAIRES_ANNOTATION_UPDATE,
      set_screen_recording_supported: SCREEN_RECORDING_SUPPORTED,
      set_camera_recording_supported: CAMERA_RECORDING_SUPPORTED
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("candidate", {
      fetch_assessment_questionnaries: ASSESSMENT_QUESTIONNAIRES,
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS,
      submit_assessment_media: SUBMIT_APP_ASSESSMENT_MEDIA,
      set_assessment_average_time: ASSESSMENT_AVERAGE_TIME_STATS
    }),
    ...mapActions("common", {
      upload_file_chunk: UPLOAD_FILE_CHUNK
    }),
    // Set Title When Site Direction Change
    camera_enable_title() {
      return this.$t(
        "candidate.interview.permission.declined-title"
      ).toString();
    },
    screen_enable_title() {
      return this.$t(
        "candidate.interview.permission.declined-title1"
      ).toString();
    },
    // Function to process whole Annotation Process
    async init_process() {
      this.loading = true;
      try {
        await this.invokeCamera(); // Ask for camera (video/audio) permission
        // If camera permission is not supported then stop the process
        if (this.error) {
          this.loading = false;
          return;
        }
        // If user didn't allow camera permission then stop the process
        if (
          !this.camera_media_stream ||
          !this.speech_recognition ||
          !this.camera_media_recorder
        ) {
          this.loading = false;
          return;
        }
        this.camera_media_enabled = true; // Set camera enabled to true
        await this.invokeGetDisplayMedia(); // Ask for screen recording permission
        // If screen recording permission not supported
        if (this.error) {
          this.loading = false;
          return;
        }
        // If user didn't allow screen recording permission then stop the process
        if (!this.media_stream) {
          this.loading = false;
          return;
        }
        this.media_enabled = true; // Set screen recording enabled to true
        this.loading = false;
        this.current_question =
          this.app_annotation_assessments.questions[
            this.current_question_index
          ];
        let ann_ass_time =
          Number(localStorage.getItem("ann_assessment_time")) || 0;
        if (ann_ass_time > 0) {
          this.ann_assessment_time = ann_ass_time;
          localStorage.removeItem("ann_assessment_time");
        }
        let ann_pa_time = Number(localStorage.getItem("ann_part_time")) || 0;
        if (ann_pa_time > 0) {
          this.ann_part_time = ann_ass_time;
          localStorage.removeItem("ann_part_time");
        }
        let ann_ans_time = Number(localStorage.getItem("ann_answer_time")) || 0;
        if (ann_ans_time > 0) {
          this.ann_answer_time = ann_ass_time;
          localStorage.removeItem("ann_answer_time");
        }
        this.startTimer("ann_answer_time");
        this.startTimer("ann_part_time");
        this.startTimer("ann_assessment_time");
      } catch (error) {
        this.error = this.$t("assessments.unable-access").toString();
      }
    },
    // Function to invoke screen recording permission from user and start recording
    async invokeGetDisplayMedia() {
      // Check if browser supports screen recording
      if (navigator?.mediaDevices?.getDisplayMedia) {
        try {
          // Ask for screen recording permission
          this.media_stream = await navigator.mediaDevices.getDisplayMedia({
            video: true
          });
          if (!this.media_stream) return;
          this.screen_recorder = new MediaRecorder(this.media_stream); // Create a media recorder
          this.screen_recorder.start(15000); // Start recording
          this.screen_recroding_start_time = new Date(); // Set camera recording start time
          // ondataavailable event will be triggered when there is data available
          this.screen_recorder.ondataavailable = async (event) => {
            if (event.data.size > 0)
              this.screen_recoding_chunks.push(event.data);
            if (
              !this.assessment_cancelled &&
              !this.assessment_completed &&
              this.screen_recorder?.state !== "recording"
            ) {
              this.set_root_error(`${this.$t("assessments.share-screen")}`);
              this.invokeGetDisplayMedia();
              return;
            }
            if (
              this.screen_recoding_chunks.reduce(
                (sum, chunk) => sum + chunk.size,
                0
              ) >= this.screen_recording_upload_chuk_size
            ) {
              this.upload_recording_chunks(AssessmentRecordType.SCREEN);
            }
          };
          // onstop event will be triggered when user stops recording
          this.screen_recorder.onstop = () => {
            if (this.screen_recorder) {
              this.screen_recorder.stream
                .getTracks()
                .forEach((track) => track.stop());
              this.screen_recorder.ondataavailable = null;
              this.screen_recorder.onstop = null;
              this.screen_recorder = null;
              this.media_stream = null;
              this.screen_recroding_end_time = new Date(); // Set camera recording end time
            }
          };
          this.set_screen_recording_supported(true);
        } catch (error) {
          this.media_enabled = false;
          this.media_stream = null;
          this.screen_recorder = null;
        }
      } else {
        this.error = this.$t("assessments.browser-unsupport").toString();
        this.media_enabled = false;
        this.media_stream = null;
        this.screen_recorder = null;
        this.set_screen_recording_supported(false);
        this.loading = false;
      }
    },
    async invokeCamera() {
      if (navigator?.mediaDevices?.getUserMedia) {
        try {
          const result = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
          });
          if (result.active) {
            this.camera_media_stream = result;
            await this.config_speech_recognition();
            await this.config_video_recording(result);
            this.set_camera_recording_supported(true);
          }
          this.set_camera_recording_supported(true);
        } catch (e) {
          this.camera_media_stream = null;
          this.speech_recognition = null;
          this.camera_media_recorder = null;
          this.error = this.$t("assessments.permission-denied").toString();
        }
      } else {
        this.error = this.$t("assessments.browser-unsupport-camera").toString();
        this.camera_media_stream = null;
        this.speech_recognition = null;
        this.camera_media_recorder = null;
        this.loading = false;
        this.set_camera_recording_supported(false);
      }
    },
    async config_speech_recognition() {
      try {
        const speech_recognition = window.webkitSpeechRecognition;
        this.speech_recognition = new speech_recognition();
        this.speech_recognition.interimResults = true;
        this.speech_recognition.continuous = true;
      } catch (e) {
        throw this.$t("assessments.no-speech-recognition").toString();
      }
    },
    async config_video_recording(stream: MediaStream) {
      try {
        this.camera_media_recorder = new MediaRecorder(stream);
        this.camera_media_recorder.start(15000);
        this.camera_recording_start_time = new Date();
        const video = this.$refs.camera as HTMLVideoElement;
        video.srcObject = stream;
        this.camera_media_recorder.ondataavailable = this.media_recorder_data;
        this.camera_media_recorder.onstop = this.handle_media_recorder_stop;
      } catch (e) {
        throw this.$t("assessments.unable-record").toString();
      }
    },
    handle_media_recorder_stop() {
      if (this.camera_media_recorder) {
        this.camera_media_recorder.stream
          .getTracks()
          .forEach((track) => track.stop());
        this.camera_media_recorder.ondataavailable = null;
        this.camera_media_recorder.onstop = null;
        this.camera_media_recorder = null;
        this.camera_media_stream = null;
        this.media_stopped = true;
        this.camera_recording_end_time = new Date();
        if (
          this.get_screen_recording_supported &&
          this.get_camera_recording_supported &&
          this.assessment_completed
        )
          this.process_assessment_completed();
      }
    },
    async media_recorder_data(data: BlobEvent) {
      if (data.data && data.data.size > 0) {
        this.camera_recorded_blobs.push(data.data);
      }
      if (
        this.camera_recorded_blobs.reduce(
          (sum, chunk) => sum + chunk.size,
          0
        ) >= this.camera_recording_upload_chuk_size
      ) {
        this.upload_recording_chunks(AssessmentRecordType.CAMERA);
      }
    },
    startTimer(time: keyof Vue["$data"]) {
      // If a timer for this variable already exists, clear it first
      if (this.timers[time]) {
        clearInterval(this.timers[time]);
      }
      // Start a new interval and store the ID in the object
      this.timers[time] = setInterval(() => {
        (this as any)[time]++;
      }, 1000);
    },
    // Function to get text field message based on  bot speaking
    text_field_msg() {
      return this.$t("candidate.interview.listening").toString();
    },
    submit_part1(data: { text: string; index: number }[]) {
      const sortedResp = [...data].sort((a, b) => a.index - b.index);
      this.submit_answer(sortedResp);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submit_answer(data: any) {
      if (!this.current_question) return;
      // If current question is part 1 then store the answer in current_question_answer
      // Setting current question answer
      this.current_question_answer = {
        answers: data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      // Setting current question answer in store
      this.update_app_annotation_assessment({
        question_id: this.current_question.id,
        answer: {
          answer_type: AppAssessments.AnswerType.TEXT,
          answer: this.current_question_answer
        }
      });
      this.disabled_button(); // Disable next button
      let payload = {
        answer_time: this.ann_answer_time.toString()
      };
      if (
        this.current_question?.question_options?.ref !==
        AppAssessments.AnnotationAssessmentRefs.PART8
      ) {
        this.set_assessment_average_time(payload);
        this.ann_answer_time = 0;
      }
    },
    // Process next question and set current question to next question if exist
    async process_next_question() {
      localStorage.setItem("current_question", this.current_question_index + 1);
      let payload = {};
      this.submit_ans_loading = true; // Set submit answer loading
      if (!this.current_question) return; // If current question not exist then return
      // Api Call to save current question answer
      const api_payload: SubmitAssessmentAnswersApiPayload = {
        answer: JSON.stringify(this.current_question_answer) ?? "",
        question_id: this.current_question.id,
        answer_type: AppAssessments.QuestionType.JSON,
        assessment_id: this.current_question.assessment_id
      };
      // Set done to true if current question index is last question index
      if (
        this.current_question_index ===
        this.app_annotation_assessments.questions.length - 1
      ) {
        api_payload.done = true;
        localStorage.removeItem("current_question");
      }
      const response = await this.submit_assessment_answers(api_payload);
      // If response not exist then set root error and return
      if (!response) {
        this.set_root_error(`${this.$t("assessments.went-wrong")}`);
        this.submit_ans_loading = false; // Set submit answer loading
        this.disabled_next_question = true; // Disable next button
        return;
      }
      payload = {
        [`${this.current_question?.question_options?.ref}_time`]:
          this.ann_part_time.toString()
      };
      this.ann_part_time = 0;
      // If current question index is less than total questions length then set current question to next question
      if (
        this.current_question_index <
        this.app_annotation_assessments.questions.length - 1
      ) {
        this.increment_question_index(this.current_question_index + 1); // Increment current question index
        this.current_question_answer = ""; // Reset current question answer
        this.current_question =
          this.app_annotation_assessments.questions[
            this.current_question_index
          ]; // Set current question to next question
      } else {
        payload = {
          ...payload,
          annotation_assessment_time: this.ann_assessment_time.toString(),
          answer_time: this.ann_answer_time.toString()
        };
        this.ann_answer_time = 0;
        this.ann_part_time = 0;
        this.ann_assessment_time = 0;
        await this.process_assessment_completed();
      }
      this.set_assessment_average_time(payload);

      this.submit_ans_loading = false; // Set submit answer loading
      this.disabled_next_question = true; // Disable next button
    },
    // Function to process assessment completion event
    // Upload screen recording and camera recording to server
    // Navigate to dashboard
    async process_assessment_completed() {
      this.set_app_annotation_assessments({
        questions: this.app_annotation_assessments.questions,
        cancelled: false,
        completed: true,
        current_question_index: this.current_question_index
      });
      if (!this.media_stopped) {
        if (this.speech_recognition) this.speech_recognition.stop();
        if (this.screen_recorder) this.screen_recorder.stop();
        if (this.camera_media_recorder) this.camera_media_recorder.stop();
        return;
      }
      await this.upload_recording_chunks(AssessmentRecordType.SCREEN);
      await this.upload_recording_chunks(AssessmentRecordType.CAMERA);
      localStorage.removeItem("ann_answer_time");
      localStorage.removeItem("ann_part_time");
      localStorage.removeItem("ann_assessment_time");
      await this.$router.push("/candidate/dashboard");
    },
    async process_reshare_screen() {
      await this.invokeGetDisplayMedia();
      if (this.media_stream) {
        this.media_enabled = true;
        this.error = "";
      }
    },
    on_copy(event: Event) {
      event.preventDefault();
    },
    async uploadFileWithChunks(file: File, filename: string) {
      this.media_uploading = true; // Set media uploading to true
      let retry = 0;
      const chunkSize = 2 * 1024 * 1024; // 1MB chunk size
      let start = 0;
      // Loop until start is less than file size
      while (start < file.size) {
        let end = Math.min(start + chunkSize, file.size); // Get end index
        let chunk = file.slice(start, end); // Get chunk
        const formData = new FormData(); // Create a form data
        formData.append("file", chunk, filename); // Append chunk to form data
        // formData.append("start", start.toString()); // Append start index to form data
        formData.append("filename", filename); // Append file name to form data
        const response = await this.upload_file_chunk(formData); // Api call to upload file chunk
        if (response && retry < 15) {
          start = end; // If response exist then set start to end
          retry = 0;
          this.media_uploading_progress = Math.round((start / file.size) * 100); // Set media uploading progress
        } else if (retry >= 15) {
          this.set_root_error(`${this.$t("assessments.upload-file-error")}`);
          break;
        } else {
          retry += 1;
        }
      }
      this.media_uploading = false; // Set media uploading to false
      this.media_uploading_progress = 0; // Set media uploading progress to 0
      this.media_uploading_title = ""; // Set media uploading title to empty
    },
    async upload_recording_chunks(type: AssessmentRecordType) {
      const assessment_id =
        this.app_annotation_assessments.questions[0].assessment_id; // Get assessment id
      const file_name = `assessment_${assessment_id}_user_${this.get_user.id}_${type}_recording.webm`;
      const blob_pick =
        type === AssessmentRecordType.SCREEN
          ? this.screen_recoding_chunks
          : this.camera_recorded_blobs;
      const fixedBlob = new Blob(blob_pick, {
        type: "video/webm"
      });
      // Clear blobs
      if (type === AssessmentRecordType.SCREEN) {
        this.screen_recoding_chunks = [];
      } else if (type === AssessmentRecordType.CAMERA) {
        this.camera_recorded_blobs = [];
      }
      const arrayBuffer = await fixedBlob.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const bucket_prefix =
        this.get_company_details?.s3_path?.assessments_path
          .split("/")
          .slice(1)
          .join("/") ?? "open/assessments/inception";
      socketService.socket.emit("media-recorder-recording", {
        recording: buffer,
        user_id: this.get_user.id,
        file_name: file_name,
        completed: this.assessment_completed,
        cancelled: this.assessment_cancelled,
        assessment_id: assessment_id,
        assessment_type:
          this.get_company_details?.s3_path?.assessment_setting
            ?.candidates_annotation ?? "unknown",
        bucket_prefix,
        recording_type: type
      });
    },
    disabled_button() {
      // Current Index of Annotation Part
      const index = this.app_annotation_assessments.current_question_index;
      console.log(index, "========index");
      const assessment = this.app_assessments.find(
        (val: AppAssessments.Assessment) =>
          val.id === this.current_question?.assessment_id
      );
      if (assessment.assessment_type == "annotation_v3") {
        if (index === 2) {
          this.disabled_next_question =
            !this.app_annotation_assessments.questions[
              this.current_question_index
            ].answer?.answer?.answers.every((item: any) => item?.answer !== "");
        } else {
          this.disabled_next_question = false; // disable button for next question
        }
      } else {
        if (index === 0) {
          this.disabled_next_question = false; // disable button for next question
        } else if (index === 1) {
          // for annotation part 1
          if (
            this.app_annotation_assessments.questions[
              this.current_question_index
            ].answer?.answer?.answers.length
          ) {
            this.disabled_next_question = false;
          }
          // Check every option is selected or not
          this.disabled_next_question =
            !this.app_annotation_assessments.questions[
              this.current_question_index
            ].answer?.answer?.answers.every((item: AnnotationPartInterface) =>
              item?.options?.every(
                (option: OptionsPart1) =>
                  (option.op1_selected === true &&
                    option.op2_selected === false) ||
                  (option.op1_selected === false &&
                    option.op2_selected === true)
              )
            );
        } else if (index === 2 || index === 3) {
          // Every Answer is fill or not
          this.disabled_next_question =
            !this.app_annotation_assessments.questions[
              this.current_question_index
            ].answer?.answer?.answers?.every((item: AnnotationAnswers) => {
              return item?.answer?.length > 0;
            });
        } else if (index === 4) {
          // Every Answer & Question is fill or not
          this.disabled_next_question =
            !this.app_annotation_assessments.questions[
              this.current_question_index
            ].answer?.answer?.answers?.every((item: AnnotationAnswers) => {
              return item?.answer?.length > 0 && item?.question?.length > 0;
            });
        } else if (index === 8) {
          console.log(
            this.app_annotation_assessments.questions[
              this.current_question_index
            ]
          );
        }
      }
    },
    async beforeUnload(e: BeforeUnloadEvent) {
      localStorage.setItem(
        "ann_assessment_time",
        this.ann_assessment_time.toString()
      );
      localStorage.setItem("ann_part_time", this.ann_part_time.toString());
      localStorage.setItem("ann_answer_time", this.ann_answer_time.toString());
    }
  },
  beforeDestroy() {
    if (this.speech_recognition) this.speech_recognition.stop();
    if (this.screen_recorder) this.screen_recorder.stop();
    if (this.camera_media_recorder) this.camera_media_recorder.stop();
  }
});







































import InstructionsDialogBox from "@/components/shared/InstructionsDialogBox.vue";
import { AppAnnotation7Answers } from "@/interfaces/candidate/canidate_assessments";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "AppAnnotationPart7",
  components: {
    InstructionsDialogBox
  },
  data() {
    return {
      options: [] as AppAnnotation7Answers[]
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  mounted() {
    if (
      this.question.question_options &&
      this.question.question_options.responses
    ) {
      for (let res of this.question.question_options.responses) {
        if (!res.question || !res.answer || !res.correct_answer) continue;
        this.options.push({
          score: 0,
          question: res.question,
          answer: res.answer,
          correct_answer: res.answer
        });
      }
    }
  },
  methods: {
    submit_ans() {
      this.$emit("annotation-7", this.options);
    },
    event_cancellation(event: any) {
      event.preventDefault();
    }
  }
});

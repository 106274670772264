
















































































import { AppAnnotation6Answers } from "@/interfaces/candidate/canidate_assessments";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "AppAnnotationPart6",
  data() {
    return {
      options: [] as AppAnnotation6Answers[],
      example_questions: [] as AppAnnotation6Answers[],
      is_example_completed: false,
      dialog: true
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  mounted() {
    if (
      this.question.question_options &&
      this.question.question_options.responses
    ) {
      let remainingQuestions = [];

      // Add the first two questions to `firstTwoQuestions` with the `correct_answer` field.
      for (
        let i = 0;
        i < this.question.question_options.responses.length;
        i++
      ) {
        const res = this.question.question_options.responses[i];
        if (!res.question) continue;

        if (i < 1) {
          this.example_questions.push({
            question: res.question,
            correct_answer: res.ans || "", // Assuming `correct_answer` exists; otherwise, set default.
            answer: "" // Add empty `answer` field.
          });
        } else {
          remainingQuestions.push({
            question: res.question,
            answer: "" // Add empty `answer` field.
          });
        }
      }

      // Shuffle the remaining questions.
      remainingQuestions = remainingQuestions.sort(() => Math.random() - 0.5);

      // Add 4 random questions from the shuffled list to the `this.options` array.
      this.options.push(...remainingQuestions);
    }
  },
  methods: {
    submit_ans() {
      console.log({ ...this.options[0] });
      this.$emit("annotation-6", this.options);
    },
    event_cancellation(event: any) {
      event.preventDefault();
    },

    start_assessment() {
      this.is_example_completed = true;
    }
  }
});

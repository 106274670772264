






















































































































import InstructionsDialogBox from "@/components/shared/InstructionsDialogBox.vue";
import { AppAnnotation8Answers } from "@/interfaces/candidate/canidate_assessments";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { get } from "http";
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "AppAnnotationPart8",
  components: {
    InstructionsDialogBox
  },
  data() {
    return {
      options: [] as AppAnnotation8Answers[],
      dialog: true,
      is_example_done: false,
      example_questions: [] as AppAnnotation8Answers[],
      audioRefs: [] as Array<HTMLAudioElement>,
      timerStarted: false,
      answer_editor_options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"]
          ]
        },
        placeholder: "Type answer here",
        table: [] // Answer editor options
      }
    };
  },
  props: {
    question: {
      type: Object as PropType<AppAssessments.AssessmentQuestionnaires>,
      required: true
    }
  },
  mounted() {
    if (
      this.question.question_options &&
      this.question.question_options.responses
    ) {
      for (let res of this.question.question_options.responses) {
        if (!res.audio_url) continue;
        if (res.is_example) {
          this.example_questions.push({
            answer: res.ans ? res.ans : "",
            audio_url: res.audio_url
          });
        } else {
          this.options.push({
            answer: "",
            audio_url: res.audio_url
          });
        }
      }
    }
    console.log(this.options[0].audio_url, "===================");
  },
  methods: {
    submit_ans() {
      this.$emit("annotation-8", this.options);
    },
    event_cancellation(event: any) {
      event.preventDefault();
    },
    handleAudioPlay() {
      if (!this.timerStarted) {
        this.timerStarted = true; // Start the timer only on the first play
        this.$emit("start-timer");
      }
    },
    quill() {
      const quill = this.$refs?.answer_editor as any;
      if (quill && quill.quill) return quill.quill;
      else return null;
    },
    reset_audio() {
      // Iterate through refs and reload each audio element
      if (this.$refs.audioRefs) {
        (this.$refs.audioRefs as HTMLAudioElement[]).forEach(
          (audio: HTMLAudioElement) => {
            if (audio) {
              audio.load(); // Reset the audio element
            }
          }
        );
      }
    },
    start_assessment() {
      this.reset_audio();
      this.is_example_done = true;
    },
    get_audio_url(url: string) {
      const time = new Date().getTime();

      return `${url}?t=${time}`;
    }
  }
});
